import { useLocation } from 'react-router-dom';
import { storage } from 'src/lib/utils';

let used = false;

export const usePreLoginAuthLocation = () => {
  const rememberLocation = (object: ReturnType<typeof useLocation>) => {
    if (used) {
      return object;
    }

    storage.set('intendedPreLoginAuthLocation', object);
    return object;
  };

  const getLocation = (fallback: ReturnType<typeof useLocation>) => {
    const result = storage.get<ReturnType<typeof useLocation>>(
      'intendedPreLoginAuthLocation',
      true,
    );
    storage.remove('intendedPreLoginAuthLocation');
    used = true;
    return result || fallback;
  };

  return { rememberLocation, getLocation };
};
