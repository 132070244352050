import React, { createContext, Dispatch, useState } from 'react';

interface UpsellContext {
  showDialog: boolean;
  setShowDialog: Dispatch<React.SetStateAction<boolean>>;
}

const UpsellDialogContext = createContext<UpsellContext>({
  showDialog: false,
  setShowDialog: () => {
    return;
  },
});

const UpsellDialogContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <UpsellDialogContext.Provider value={{ showDialog, setShowDialog }}>
      {children}
    </UpsellDialogContext.Provider>
  );
};

const useUpsellDialogContext = () => {
  const context = React.useContext(UpsellDialogContext);

  if (!context) {
    throw new Error('useUpsellDialog must be used within a UpsellDialogContextProvider');
  }

  return context;
};

export { UpsellDialogContextProvider, useUpsellDialogContext };
