import { ValueOfObject } from 'src/types/generics';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  StripeProductObject,
  SubscriptionAddonType,
  SubscriptionProductType,
} from 'src/api/services/Subscription';

export const subscriptionPlanType = {
  scale: 'scale',
  pro: 'pro',
} as const;

export type SubscriptionPlanType = ValueOfObject<typeof subscriptionPlanType>;

export const subscriptionBillingInterval = {
  monthly: 'monthly',
  quarterly: 'quarterly',
  annually: 'annually',
  oneTime: 'one_time',
} as const;

export type SubscriptionBillingIntervalType =
  | ValueOfObject<typeof subscriptionBillingInterval>
  | string;

export const paymentMethod = {
  card: 'card',
  googlePay: 'googlePay',
  applePay: 'applePay',
};

export type PaymentMethodType = ValueOfObject<typeof paymentMethod> | string;

export type SubscriptionFormValues = {
  payment_method: PaymentMethodType;
  coupon: string;
  plan: SubscriptionPlanType;
  billing_interval: SubscriptionBillingIntervalType;
  promotion_code?: PromotionCodeResponse;
};

export interface StripeProduct<T> {
  id: string;
  slug: T;
  title: string;
  subtitle_one: string;
  subtitle_two: string;
  description: string;
  prices: { [key in SubscriptionBillingIntervalType]: StripePrice };
  meta: Record<string, any>;
}

export interface StripePrice {
  price_id: string;
  price: number;
  interval: SubscriptionBillingIntervalType;
}

export type ProductFeatures = {
  [key in SubscriptionPlanType]: { [key: string]: boolean };
};

export type StripePlan = {
  included_addons: { [key in SubscriptionBillingIntervalType]: unknown[] };
} & StripeProduct<SubscriptionPlanType>;

export interface StripeProductsResponse {
  plans: StripePlan[];
  plan_features: ProductFeatures;
  // todo: maybe we can remove these from back-end too?
  // addons: unknown[];
  // addon_features: ProductFeatures;
}

export interface ValidatePromotionCodeRequest {
  promotion_code: string;
}

export interface PromotionCodeResponse {
  id: string;
  active: boolean;
  code: string;
  amount_off: number | null;
  percent_off: number | null;
  duration: string;
  duration_in_months: number | null;
  valid: boolean;
  applies_to_products: string[];
}

export type SubscriptionPaymentFormRequest = {
  payment_method: PaymentMethodType;
  plan: SubscriptionPlanType;
  card_holder: string | undefined;
  card_number_complete: boolean | undefined;
  card_expiry_complete: boolean | undefined;
  card_cvc_complete: boolean | undefined;
  generate_invoice?: boolean;
} & Record<string, any>;

export interface StripePaymentIntentRequest {
  payment_method: string;
  products: string[];
  promotion_code?: string;
}

export interface StripeIntentResponse {
  client_secret: string;
}

export type SubscriptionAssignmentResponse = {
  id: string;
  subscription: string;
  editor_change_requested_at?: string;
  editor_change_request_reason?: string;
  editor: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
};

export interface SubscriptionProductResponse {
  id: string;
  product_id: string;
  price_id: string;
  title: string;
  slug: SubscriptionPlanType;
  price: number;
  interval: SubscriptionBillingIntervalType;
}

export interface CreateSubscriptionRequest {
  /**
   * TODO: BAMBOOZL ALERT, SERVER REQUIRES SETUP INTENT ID NOT PAYMENT ID :angry:
   */
  payment_id: string;
  payment_method?: PaymentMethod | string;
  generate_invoice?: boolean;

  // API extended to support company update before subscription creation because wallet payment sheets needs to happen right after click.
  company?: {
    name?: string;
    country_code?: string;
    billing_email?: string;
    address?: string;
    contact_phone?: string;
    city?: string;
    zip_code?: string;
  };
}

export interface CreateStripeCustomerRequest {
  first_name?: string;
  last_name?: string;
  email: string;
  company?: string;
  tos: boolean;
}

export interface CreateStripeCustomerResponse {
  token: string;
}

export type CancelSubscriptionRequest = {
  reason: string[];
  other_solution: string;
  likely_to_return?: number;
  likely_to_suggest?: number;
  feedback: string;
};

export interface SubscriptionObject {
  id: string;
  status: string;
  ends_at?: string;
  products: SubscriptionProductObject[];
  provider_data: Record<string, any> | undefined;
  previous_payment: {
    paid_at: number;
    amount: number;
  };
  upcoming_payment: {
    due_at: number;
    amount: number;
  } | null;
  current_period_start: string | number;
  current_period_end: string | number;
  discount?: {
    start?: string;
    end?: string;
    amount_off?: number;
    percent_off?: number;
  };
  assignment?: SubscriptionAssignmentResponse;
  plan: Omit<SubscriptionProductObject, 'included_addons' | 'type'> & {
    subtitle: string;
    description: string;
    features: PlanFeature[];
  };
  schedule?: {
    stripe_id: string;
    type: 'downgrade' | string;
    status: string;
    created_at: string;
  };
}

export interface SubscriptionProductObject {
  id: string;
  product_id: string;
  price_id: string;
  title: string;
  slug: SubscriptionAddonType | SubscriptionPlanType;
  price: number;
  interval: SubscriptionBillingIntervalType;
  /** @deprecated */
  type: SubscriptionProductType;
  /** @deprecated */
  included_addons?: StripeProductObject<SubscriptionAddonType>[];
}

type PlanFeature = {
  type: string;
  name: string;
  sort: number;
  enabled: boolean;
};

type PlanPrice = {
  price_id: string;
  price: number;
  interval: SubscriptionBillingIntervalType;
};

export type SubscriptionPlanChangeResponse = {
  id: string;
  slug: 'pro' | 'scale';
  title: string;
  subtitle: string;
  description: string;
  features: PlanFeature[];
  price: PlanPrice;
  amount_due: number;
};
