import React from 'react';
import { useCurrentRequest, useRequestsCounts } from 'src/models';
import { useTranslation } from 'react-i18next';
import { If } from 'src/components/If';
import { RateRevisionComposedDialog } from 'src/features/revisions/rate-revision-composed-dialog';
import { customPosthogEvent, useDecoratedPostHog } from 'src/providers/posthog';
import { useQueryClient } from '@tanstack/react-query';
import { authQueries } from 'src/api/queries';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { useToggle } from 'src/lib/state-utils';
import { useToast } from 'src/components/ui/use-toast';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestMutations } from 'src/features/requests/use-request-mutations';
import { Button } from 'src/components/ui/button';
import { CheckCircle, ChevronLeft, Megaphone, ScissorsIcon, Video } from 'lucide-react';
import { DialogTrigger } from 'src/components/ui/dialog';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';
import { useConfirm } from 'src/components/ConfirmModal';
import { ProfileResponse } from 'src/lib/services/api/profile-api';
import { requestStatus } from 'src/lib/services/api/request-api';
import { useRepurposeRequestMutation } from 'src/features/repurpose/use-repurpose-request-mutation';
import { useUpsell } from 'src/features/upsell-dialog/use-upsell';

const RevisionsBreadcrumbs: React.FC = () => {
  const { t } = useTranslation('pages/shared');
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = useCurrentRequest();

  return (
    <PageHeading>
      <Breadcrumb>
        <BreadcrumbList className={'tw-flex-nowrap'}>
          <BreadcrumbItem>
            <Button
              variant={'unset'}
              size={'iconXs'}
              onClick={() => {
                if (location.key !== 'default') {
                  history.back();
                } else {
                  navigate(appRoutes.requestsList);
                }
              }}
            >
              <ChevronLeft className={'tw-size-4'} />
            </Button>
            <BreadcrumbLink asChild>
              <Link to={appRoutes.dashboard}>
                {t('common:dashboard', {
                  defaultValue: 'Dashboard',
                })}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={appRoutes.requestsList}>
                {t('common:requests', {
                  defaultValue: 'Requests',
                })}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>
            {t('common:revisions', {
              defaultValue: 'Revisions',
            })}
          </BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeadingTitle className={'tw-flex-nowrap'}>
        <div>
          <Video className={'tw-size-6'} />
        </div>
        <span className={'tw-line-clamp-1'}>
          {title || t('common:revision', { defaultValue: 'Request revision' })}
        </span>
      </PageHeadingTitle>
    </PageHeading>
  );
};

const OutcomeRevisionsBreadcrumbs: React.FC<{ title: string; parentId: string }> = ({
  title,
  parentId,
}) => {
  const { t } = useTranslation('pages/shared');
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PageHeading>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <Button
              variant={'unset'}
              size={'iconXs'}
              onClick={() => {
                if (location.key !== 'default') {
                  history.back();
                } else {
                  navigate(generatePath(appRoutes.request, { requestId: parentId }));
                }
              }}
            >
              <ChevronLeft className={'tw-size-4'} />
            </Button>
            <BreadcrumbLink asChild>
              <Link to={appRoutes.dashboard}>
                {t('common:dashboard', {
                  defaultValue: 'Dashboard',
                })}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={appRoutes.requestsList}>
                {t('common:requests', {
                  defaultValue: 'Requests',
                })}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={generatePath(appRoutes.request, { requestId: parentId })}>
                {t('common:Request', {
                  defaultValue: 'Request',
                })}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>
            {t('common:revisions', {
              defaultValue: 'Revisions',
            })}
          </BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeadingTitle className={'tw-flex-nowrap'}>
        <div>
          <Video className={'tw-size-6'} />
        </div>
        <span className={'tw-line-clamp-1'}>
          {title || t('common:revision', { defaultValue: 'Request revision' })}
        </span>
      </PageHeadingTitle>
    </PageHeading>
  );
};

const RevisionsContentTop: React.FC = () => {
  const { t } = useTranslation('pages/shared');
  const postHog = useDecoratedPostHog();
  const client = useQueryClient();
  const { confirm } = useConfirm();

  const request = useDecoratedRequestContext();
  const requestMutations = useRequestMutations();

  const { toast } = useToast();
  const [rateRevisionDialogOpen, setRateRevisionDialogOpen] = useToggle();
  const { update } = useRequestsCounts(
    {},
    {
      enabled: false,
    },
  );

  const latestRevision = [...(request?.revisions ?? [])].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  )?.[0];

  const capturePosthogEvent = (rating: number) => {
    const profile = client.getQueryData<ProfileResponse>([authQueries.profile]);
    const company = profile?.company;

    postHog.capture(customPosthogEvent.requestRevisionRated, {
      company: {
        id: company?.id ?? 'unknown',
        name: company?.name ?? 'Unknown',
      },
      request: {
        id: request.id,
        name: request.title ?? 'Unknown',
      },
      editor:
        joinExistingValues([request?.editor?.first_name, request?.editor?.last_name], ' ') ??
        'Unknown',
      requestRevisionRating: rating,
    });
  };

  // Should keep this
  const updateRequestQueryData = (rating: number) => {
    requestMutations.setQueryData(request.id, (prev) => {
      if (!prev) {
        return prev;
      }

      const next = { ...prev };

      next.rated_revisions_count = (next.rated_revisions_count ?? 0) + 1;

      if (next.revisions.length) {
        next.revisions = next.revisions.map((revision) => {
          if (revision.id === latestRevision?.id) {
            revision.rating = rating;
          }

          return revision;
        });
      }

      return next;
    });
  };

  // Should keep this
  const handleRevisionRated = (rating: number) => {
    capturePosthogEvent(rating);
    updateRequestQueryData(rating);
  };

  const handleRequestReedit = async () => {
    return requestMutations.requestReedit.mutateAsync(request.id, {
      onSuccess: () => {
        toast({
          description: t('alerts:success.request_reedit', {
            defaultValue: 'Re-edit request was sent to your editor!',
          }),
        });
      },
    });
  };

  return (
    <div className={'tw-mb-4 tw-flex tw-flex-wrap tw-gap-4 md:tw-mb-10'}>
      <div
        className={
          'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 md:tw-justify-start'
        }
      >
        <If when={request.flow === 'outcome'} else={<RevisionsBreadcrumbs />}>
          <OutcomeRevisionsBreadcrumbs title={request.title} parentId={request!.parent_id!} />
        </If>
      </div>

      <div className={'tw-ms-auto tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2'}>
        <If
          when={request.canRateRevision}
          else={
            <If when={request.canRequestReEdit}>
              <Button
                variant={'outline'}
                size={'iconStartResponsive'}
                disabled={requestMutations.requestReedit.isLoading}
                onClick={handleRequestReedit}
              >
                <Megaphone className={'tw-size-5'} />
                {t('actions:request_reedit', { defaultValue: 'Request re-edit' })}
              </Button>
            </If>
          }
        >
          <RateRevisionComposedDialog
            revision={latestRevision?.id}
            onSuccess={handleRevisionRated}
            open={rateRevisionDialogOpen}
            onOpenChange={setRateRevisionDialogOpen}
          >
            <If when={request.canRequestReEdit}>
              <DialogTrigger asChild>
                <Button
                  variant={'outline'}
                  size={'iconStartResponsive'}
                  disabled={requestMutations.requestReedit.isLoading}
                  onClick={() => {
                    handleRequestReedit().then(() => setRateRevisionDialogOpen(true));
                  }}
                >
                  <Megaphone className={'tw-size-5'} />
                  {t('actions:request_reedit', { defaultValue: 'Request re-edit' })}
                </Button>
              </DialogTrigger>
            </If>
          </RateRevisionComposedDialog>
        </If>

        <If when={request.canApprove}>
          <Button
            variant={'accent1'}
            size={'responsive'}
            disabled={requestMutations.approve.isLoading}
            onClick={() => requestMutations.approve.mutate(request.id)}
          >
            {t('actions:approve', { defaultValue: 'Approve' })}
          </Button>
        </If>

        <If when={request.canComplete}>
          <Button
            variant={'brand'}
            size={'iconStartResponsive'}
            disabled={requestMutations.complete.isLoading || !request.canComplete}
            onClick={async () => {
              const confirmed = await confirm('', {
                modal_header: {
                  content: t('alerts:confirm.complete_request.title', {
                    defaultValue: 'Complete request',
                  })!,
                },
                modal_body: {
                  content: t('alerts:confirm.complete_request', {
                    defaultValue: 'Are you sure you want to complete this request?',
                  })!,
                },
              });

              if (!confirmed) {
                return;
              }

              requestMutations.complete.mutate(request.id, {
                onSuccess: () => {
                  // Update counts
                  update(request.id, requestStatus.complete);

                  toast({
                    description: t('alerts:success.request_completed', {
                      defaultValue: 'Request has been completed!',
                    }),
                  });
                },
              });
            }}
          >
            <CheckCircle className={'tw-size-5'} />
            {t('actions:complete', { defaultValue: 'Complete' })}
          </Button>
        </If>
        <RepurposeAction />
      </div>
    </div>
  );
};

export { RevisionsContentTop };

const RepurposeAction: React.FC = () => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();
  const client = useQueryClient();
  const mutateRepurposeRequest = useRepurposeRequestMutation();
  const { checkRequestsPrivileges } = useUpsell();

  const confirm = async () => {
    await checkRequestsPrivileges(async () => {
      mutateRepurposeRequest.mutate([request?.id], {
        onSuccess: () => {
          client.invalidateQueries({
            queryKey: [authQueries.requests, request.id],
          });
        },
      });
    });
  };

  return (
    <>
      {request?.repurposable_at && (
        <Button variant={'brand'} size={'iconStartResponsive'} type={'button'} onClick={confirm}>
          <ScissorsIcon size={16} />
          <span>{t('Clip')}</span>
        </Button>
      )}
    </>
  );
};
