import { AxiosRequestConfig } from 'axios';
import { httpConfig } from 'src/lib/modules/http';
import { Api } from 'src/api';
import {
  ChangePasswordRequest,
  CompanyAccountAuthorizationConfigResponse,
  ProfileResponse,
  UpdateCompanyRequest,
  UpdateProfileRequest,
  UploadAvatarRequest,
} from 'src/lib/services/api/profile-api/profile-client.types';
import { MediaObject } from 'src/api/services/MediaClient';

const baseUrl = `${httpConfig.options.baseURL}/profile`;

const updateCompany = (data: UpdateCompanyRequest, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(`${baseUrl}/company`, data, config);

const get = (config?: AxiosRequestConfig): Promise<ProfileResponse> =>
  Api.http.get(`${baseUrl}`, config);

const update = (data: UpdateProfileRequest, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(`${baseUrl}`, data, config);

const changePassword = (data: ChangePasswordRequest, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(`${baseUrl}/change-password`, data, config);

const stopImpersonating = (config?: AxiosRequestConfig): Promise<string> =>
  Api.http.delete(`${baseUrl}/impersonate/stop`, config);

const attachAvatar = (data: UploadAvatarRequest, config?: AxiosRequestConfig): Promise<MediaObject> =>
  Api.http.post(`${baseUrl}/avatar`, data, config);

const deleteAvatar = (config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(`${baseUrl}/avatar`, config);

const getAuthorizationConfig = (config?: AxiosRequestConfig): Promise<CompanyAccountAuthorizationConfigResponse> =>
  Api.http.get(`${baseUrl}/account-authorization-config`, config);

export {
  updateCompany,
  get,
  update,
  changePassword,
  stopImpersonating,
  attachAvatar,
  deleteAvatar,
  getAuthorizationConfig,
};
