import React, { useState } from 'react';
import { Text } from '@becreatives/becreatives-ui';
import { BxLoaderCircleIcon, BxShareIcon } from 'src/components/Boxicons';
import { useToggle } from 'src/helpers/useToggle';
import { Api } from 'src/api';
import { If } from 'src/components/If';
import { useToast } from 'src/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { Clipboard, X } from 'lucide-react';

type Props = { mediaId: string } & React.ComponentPropsWithoutRef<'i'>;

const ShareMediaButton: React.FC<Props> = ({ mediaId, onClick, className, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useToggle();
  const [isOpen, setOpen, toggle] = useToggle();
  const [urls, setUrls] = useState<Record<string, string>>({});
  const { toast } = useToast();

  return (
    <>
      <If
        when={!loading}
        else={
          <BxLoaderCircleIcon {...props} className={!className ? 'spin' : `${className} spin`} />
        }
      >
        <BxShareIcon
          className={className}
          onClick={async (e) => {
            if (mediaId in urls) {
              setOpen(true);
              return;
            }

            setLoading(true);

            await Api.media
              .share(mediaId)
              .then((url) => {
                setUrls((prev) => ({ ...prev, [mediaId]: url }));
                navigator.clipboard
                  .writeText(url)
                  .then(() =>
                    toast({
                      description: t('Sharing url was copied to clipboard!'),
                      variant: 'info',
                    }),
                  )
                  .catch(() => ({}));
              })
              .then(toggle)
              .then(() => onClick?.(e))
              .catch(() => ({}))
              .finally(() => setLoading(false));
          }}
          {...props}
        />
      </If>
      <If when={mediaId in urls}>
        <Dialog open={isOpen} onOpenChange={toggle}>
          <DialogContent className={'tw-flex tw-items-center'}>
            <Text tag={'code'}>{urls[mediaId]}</Text>
            <Button
              variant={'ghost'}
              size={'sm'}
              onClick={async () => {
                await navigator.clipboard.writeText(urls[mediaId]);
                toast({
                  description: t('Sharing url was copied to clipboard!'),
                  variant: 'info',
                });
                toggle();
              }}
            >
              <Clipboard size={20} />
            </Button>
            <Button variant={'ghost'} onClick={toggle} size={'sm'}>
              <X size={20} />
            </Button>
          </DialogContent>
        </Dialog>
      </If>
    </>
  );
};

export type { Props as ShareMediaButtonProps };
export { ShareMediaButton };
