import './assets/scss/theme.scss';
import React from 'react';
import { IntlProvider } from 'react-intl';
import ModalLoaderProvider from 'src/components/ModalLoader/Provider';
import { ConfirmContextProvider, ConfirmDialog } from 'src/components/ConfirmModal';

import { AppRoutes } from 'src/routes/app-routes';
import { Toaster } from 'src/components/ui/toaster';
import AppUpdateDialog from 'src/features/sw-update/app-update-dialog';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <ModalLoaderProvider>
        <ConfirmContextProvider>
            <IntlProvider locale={'en'}>
              <Toaster />
              <AppUpdateDialog />
              <AppRoutes />
              <ConfirmDialog />
            </IntlProvider>
        </ConfirmContextProvider>
      </ModalLoaderProvider>
    </React.Fragment>
  );
};

export default App;
