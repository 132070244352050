import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject as RouterDomObject,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { RouteObject, routes } from '.';
import { NotFoundPage } from 'src/pages';
import { ProtectedRoute } from 'src/routes/protected-route';
import AuthLayout from 'src/components/ui/layouts/auth-layout';
import WrapperLayout from 'src/components/ui/layouts/wrapper-layout';

const builtRoutes = Object.keys(routes).map((key) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const route = routes[key] as RouteObject;

  return {
    element: <ProtectedRoute route={route} />,
    errorElement: (
      <WrapperLayout>
        <NotFoundPage />
      </WrapperLayout>
    ),
    children: [
      {
        path: route.path,
        lazy: route.lazy,
        element: route.element,
        index: route.index,
        loader: route.loader,
        action: route.action,
      },
    ],
  };
}) as RouterDomObject[];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const AppRoutes: React.FC = () => {
  const router = sentryCreateBrowserRouter([
    {
      element: <AuthLayout />,
      errorElement: (
        <WrapperLayout>
          <NotFoundPage />
        </WrapperLayout>
      ),
      children: [
        {
          children: [...builtRoutes],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export { AppRoutes };
