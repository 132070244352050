import { authQueries, UseQueryOptionsObject } from 'src/api/queries/queries';
import { defaultOptions } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import {
  CompanyAccountAuthorizationConfigResponse,
  profileClient,
} from 'src/lib/services/api/profile-api';

const baseAccountAuthorizationQueryKey = [authQueries.profile, 'auth-config'];

const useAccountAuthorizationQuery = (
  options?: UseQueryOptionsObject<CompanyAccountAuthorizationConfigResponse>,
) => {
  return useMakeQuery<CompanyAccountAuthorizationConfigResponse>(
    baseAccountAuthorizationQueryKey,
    () => profileClient.getAuthorizationConfig(),
    {
      staleTime: 60 * 1000,
      ...defaultOptions({
        auth: true,
        ...(options ?? {}),
        retry: (failureCount) => {
          return failureCount < 3;
        },
      }),
    },
  );
};

export { useAccountAuthorizationQuery, baseAccountAuthorizationQueryKey };
