import { UseQueryOptionsObject } from 'src/api/queries';
import { useAccountAuthorizationQuery } from 'src/api/queries/use-upsell-query/use-upsell-query';
import { CompanyAccountAuthorizationConfigResponse } from 'src/lib/services/api/profile-api';

const useAccountAuthorization = (
  options?: UseQueryOptionsObject<CompanyAccountAuthorizationConfigResponse>,
) => {
  const { data: accountAuthorizationConfig, ...accountAuthorizationQuery } = useAccountAuthorizationQuery(options);

  return {
    isFreeTier: !!accountAuthorizationConfig?.free_tier,
    remainingRequests: accountAuthorizationConfig?.free_tier?.remaining_requests,
    remainingBrands: accountAuthorizationConfig?.free_tier?.remaining_brands,
    query: accountAuthorizationQuery,
  };
};

export { useAccountAuthorization };
