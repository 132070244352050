import { useAccountAuthorization } from 'src/features/upsell-dialog/use-account-authorization';
import { useUpsellDialogContext } from 'src/features/upsell-dialog/upsell-dialog.provider';

const useUpsell = () => {
  const { remainingRequests, remainingBrands, isFreeTier, query } = useAccountAuthorization();
  const { setShowDialog } = useUpsellDialogContext();

  const checkRequestsPrivileges = async (callback?: () => Promise<void>) => {
    // if returns non number, we assume its undefined, a.k.a. has subscriptions
    if (typeof remainingRequests !== 'number') {
      await callback?.();
      return;
    }

    if (remainingRequests > 0) {
      await callback?.();
      await query.refetch();
      return;
    }

    setShowDialog(true);
  };

  const checkBrandPrivileges = async (callback?: () => Promise<void>) => {
    // if returns non number, we assume its undefined, a.k.a. has subscriptions
    if (typeof remainingBrands !== 'number') {
      await callback?.();
      return;
    }

    if (remainingBrands > 0) {
      await callback?.();
      await query.refetch();
      return;
    }

    setShowDialog(true);
  };

  return { checkRequestsPrivileges, checkBrandPrivileges, isFreeTier, query };
};

export { useUpsell };
