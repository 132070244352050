import { authQueries, useProfileQuery, UseQueryOptionsObject } from 'src/api/queries';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { pickObject } from 'src/helpers/pickObject';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import {
  profileClient,
  ProfileResponse,
  UpdateProfileRequest,
} from 'src/lib/services/api/profile-api';
import { RequestFormObject } from 'src/lib/services/api/request-api';
import { reactQueryClient } from 'src/providers/ReactQueryClientProvider';

const useProfile = (options?: UseQueryOptionsObject<ProfileResponse>) => {
  const { data: profile, ...profileQuery } = useProfileQuery(options);

  const company = profile?.company;
  const meta = company?.meta;

  const isLoaded = !profileQuery.isLoading && profileQuery.isSuccess,
    hasBrands = !!meta?.has_brands,
    hasSubscriptions = !!meta?.has_subscriptions,
    hasProvidedCompanyInformation =
      !!company?.name &&
      !!company?.billing_email &&
      !!company?.address &&
      !!company?.city &&
      !!company?.country_code &&
      !!company?.zip_code,
    hasVerifiedEmail = !!profile?.email_verified_at,
    hasActiveSubscriptions = !!company?.subscriptions?.find((s) => s.status !== 'canceled'),
    hasRequests = !!meta?.has_requests,
    submittedRequests = meta?.submitted_requests || 0,
    attentionRequiredRequests = meta?.attention_required_requests || 0,
    canCreateRequest = hasSubscriptions || !hasRequests,
    fullName = joinExistingValues([profile?.first_name, profile?.last_name], ' '),
    isImpersonated = !!AuthUtils.getPayload()?.impersonator,
    isOnboarded = hasVerifiedEmail && hasSubscriptions;

  const updateProfile = async (data: Partial<UpdateProfileRequest>) => {
    const current = pickObject(profile!, ['first_name', 'last_name', 'email', 'phone_number']);

    await profileClient.update({
      ...current,
      ...data,
    });

    profileQuery.setQueryData(data);
  };

  const canSubmitRequest = (values?: Partial<RequestFormObject>): boolean => {
    if (hasSubscriptions) {
      return true;
    }

    return values?.status === 'draft';
  };

  const stopImpersonating = async (): Promise<string> => {
    return profileClient.stopImpersonating();
  };

  return {
    ...(profile ?? {}),
    hasBrands,
    hasSubscriptions,
    hasActiveSubscriptions,
    hasRequests,
    submittedRequests,
    attentionRequiredRequests,
    canCreateRequest,
    isImpersonated,
    fullName,
    isLoaded,
    hasProvidedCompanyInformation,
    hasVerifiedEmail,
    isOnboarded,
    query: profileQuery,
    updateProfile,
    canSubmitRequest,
    stopImpersonating,
  };
};

export const getProfileData = () =>
  reactQueryClient.getQueryData<ProfileResponse>([authQueries.profile]);

export { useProfile };
