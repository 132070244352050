import React, { useEffect } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { NotFoundView } from 'src/components/NotFoundView';
import { appRoutes } from 'src/routes/routes.types';
import { Button } from 'src/components/ui/button';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const isLogged = !AuthUtils.isExpired();
  const error = useRouteError() as Error;

  const redirect = isLogged
    ? {
        to: appRoutes.dashboard,
        text: t('Back to Dashboard'),
      }
    : {
        to: appRoutes.login,
        text: t('Back to Login'),
      };

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <NotFoundView>
      <div className={'tw-mt-6'}>
        <Button asChild variant={'brand'} size={'lg'}>
          <Link to={redirect.to}>{redirect.text}</Link>
        </Button>
      </div>
    </NotFoundView>
  );
};

export { NotFoundPage };
