import React from 'react';
import { Card, CardContent } from 'src/components/ui/card';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { cn } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import dompurify from 'dompurify';
import Str from 'src/helpers/Str';
import { totalLengthToString } from 'src/features/requests/request.helper';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';

const RevisionOutcomeContentBottom: React.FC = () => {
  const { t } = useTranslation();
  const {
    status,
    type,
    description,
    editor_note,
    total_length,
    title,
    video_template,
    resolutions,
  } = useDecoratedRequestContext();

  return (
    <div className={'tw-flex tw-flex-col tw-gap-5'}>
      <h3 className="tw-text-2xl tw-font-bold tw-text-text">{t('Outcome info')}</h3>
      <Card>
        <CardContent className={'tw-px-6 tw-py-0 md:tw-py-6'}>
          {video_template?.title && (
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
                {t('Template name')}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>{video_template?.title}</div>
            </CardContentRow>
          )}
          <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
            <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
              {t('Request name')}
            </div>
            <div className={'tw-col-span-2 tw-break-words'}>
              {title ?? t('common:unset', { defaultValue: '-' })}
            </div>
          </CardContentRow>
          {resolutions?.main_size && (
            <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
              <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
                {t('Video size')}
              </div>
              <div className={'tw-col-span-2 tw-break-words'}>{resolutions.main_size}</div>
            </CardContentRow>
          )}
          <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
            <div className={'tw-col-span-1 tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
              {t('Status')}
            </div>
            <div className={'tw-col-span-2'}>
              <RequestStatusBadge variant={status}>
                {t(`models/request:status.${status}`, {
                  defaultValue: t('common:unknown', {
                    defaultValue: 'Unknown',
                  }),
                })}
              </RequestStatusBadge>
            </div>
          </CardContentRow>
          <CardContentRow
            className={'tw-min-w-0 tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}
          >
            <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>{t('Video type')}</div>
            <div className={'tw-col-span-2 tw-break-words'}>
              {t(`models/request:type.${type}`, {
                defaultValue: t('common:unknown', {
                  defaultValue: 'Unknown',
                }),
              })}
            </div>
          </CardContentRow>
          <CardContentRow
            className={'tw-min-w-0 tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}
          >
            <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
              {t('Total length')}
            </div>
            <div className={'tw-col-span-2 tw-break-words'}>
              {totalLengthToString(total_length) || t('common:unset', { defaultValue: '-' })}
            </div>
          </CardContentRow>
          <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
            <div className={'tw-mb-0 tw-align-middle tw-text-lg tw-font-bold tw-text-text'}>
              {t('Description')}
            </div>
            <div
              className={'tw-col-span-2 tw-whitespace-pre-line tw-break-words'}
              dangerouslySetInnerHTML={{
                __html: Str.wrapHrefs(
                  dompurify.sanitize(
                    description?.video_request_description ??
                      t('common:unset', {
                        defaultValue: '-',
                      })!,
                  ),
                ),
              }}
            />
          </CardContentRow>
          <CardContentRow className={'tw-gap-6 tw-border-b tw-border-neutral-100 tw-py-4'}>
            <div className={'tw-mb-0 tw-text-lg tw-font-bold tw-text-text'}>
              {t("Editor's note")}
            </div>
            <div className={'tw-col-span-2 tw-break-words'}>
              {editor_note ?? t('common:unset', { defaultValue: '-' })}
            </div>
          </CardContentRow>
        </CardContent>
      </Card>
    </div>
  );
};

const CardContentRow = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          'tw-flex tw-h-auto tw-flex-col tw-font-medium md:tw-grid md:tw-grid-cols-3 md:tw-items-center',
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

export { RevisionOutcomeContentBottom };
