import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { If } from 'src/components/If';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from 'src/lib/utils';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { Label } from 'src/components/ui/label';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { CollapsibleCard, CollapsibleCardContent } from 'src/components/ui/collapsible-card';
import { useToggle } from 'src/lib/state-utils/use-toggle';
import { Copy, Dessert, Download, FileText, FileVideo2, Link2Icon, PenLine } from 'lucide-react';
import dompurify from 'dompurify';
import { Button } from 'src/components/ui/button';
import { Href } from 'src/components/ui/href';
import { useToast } from 'src/components/ui/use-toast';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Skeleton } from 'src/components/ui/skeleton';
import { Link } from 'src/components/ui/link';
import { generatePath } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { FileIcon } from 'src/components/FileIcon';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { AlertDismissible } from 'src/components/ui/alert-dismissible';
import Str from 'src/helpers/Str';
import { formatSize } from 'src/helpers/file';
import { RequestPriorityBadge } from 'src/features/requests/request-priority-badge';

const RequestPageContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-4 md:tw-gap-10', className)}
        {...props}
      >
        {children}
      </div>
    );
  },
);
RequestPageContainer.displayName = 'RequestPageContainer';

const RequestPageContentMain = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('tw-grid tw-grow tw-gap-6', className)} {...props}>
      {children}
    </div>
  );
});
RequestPageContentMain.displayName = 'RequestPageContentMain';

const RequestInformationCardContentRow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn('tw-grid tw-h-auto tw-grid-cols-2 tw-items-start tw-font-medium', className)}
    >
      {children}
    </div>
  );
});

const FileCard: React.FC<
  React.ComponentProps<typeof Card> & {
    fileName: string;
    fileSize: number;
    mimeType: string;
    download?: string;
    thumbnail?: string;
  }
> = ({ fileName, fileSize, mimeType, download, className, thumbnail, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card className={cn(className)} {...props}>
      <CardContent className={'tw-w-full tw-items-stretch tw-p-0'}>
        <AspectRatio ratio={16 / 9}>
          <div className={'tw-relative tw-h-full tw-w-full'}>
            <div className={'tw-absolute tw-inset-0'}>
              <If
                when={!!thumbnail}
                else={
                  <div
                    className={
                      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-t-md tw-bg-indigo-200'
                    }
                  >
                    <FileIcon mimeType={mimeType} size={36} className={'tw-text-text-strong'} />
                  </div>
                }
              >
                <img
                  className={'tw-h-full tw-w-full tw-rounded-t-lg tw-object-cover'}
                  src={thumbnail}
                  alt={fileName}
                  title={fileName}
                />
              </If>
            </div>
          </div>
        </AspectRatio>
      </CardContent>
      <CardHeader className={'tw-gap-2 tw-px-3 tw-pb-0 tw-pt-3'}>
        <CardTitle
          className={
            'tw-line-clamp-2 tw-h-10 tw-w-full tw-break-all tw-text-base tw-font-bold tw-leading-normal tw-text-text'
          }
        >
          {fileName}
        </CardTitle>
        <CardDescription className={'tw-text-base'}>
          {mimeType} | {formatSize(fileSize)}
        </CardDescription>
      </CardHeader>
      <CardFooter className={'tw-justify-end tw-px-3 tw-pb-3 tw-pt-0'}>
        <If when={!!download}>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Button size={'icon'} variant={'ghost'} className={'tw-text-text'} asChild>
                  <Href variant={'unset'} href={download} target={'_blank'} rel={'noreferrer'}>
                    <Download size={20} />
                  </Href>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('actions:download')}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </If>
      </CardFooter>
    </Card>
  );
};

const RequestDefaultPageSkeleton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <RequestPageContainer ref={ref} {...props}>
      <Skeleton className={'tw-h-10 tw-w-full tw-rounded-md md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4'} />
      <RequestPageContentMain className={'tw-grid-cols-12'}>
        <Skeleton className={'tw-col-span-12 tw-h-full'} />
      </RequestPageContentMain>
    </RequestPageContainer>
  );
});

const RequestInformationCardContent: React.FC<
  React.ComponentProps<typeof CollapsibleCardContent>
> = ({ className, children, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:status', {
            defaultValue: 'Status',
          })}
        </Label>
        <div>
          <RequestStatusBadge variant={request.status}>
            {t(`models/request:status.${request.status}`, {
              defaultValue: t('common:unknown', {
                defaultValue: 'Unknown',
              }),
            })}
          </RequestStatusBadge>
        </div>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:priority', {
            defaultValue: 'Priority',
          })}
        </Label>
        <div>
          <RequestPriorityBadge variant={request.priority} />
        </div>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:title_2', {
            defaultValue: 'Request name',
          })}
        </Label>
        <p className={'tw-break-all'}>{request.title}</p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:brand', {
            defaultValue: 'Brand',
          })}
        </Label>
        <If
          when={!!request.brand}
          else={t('common:unset', {
            defaultValue: '-',
          })}
        >
          <Link
            to={generatePath(appRoutes.brand, {
              brandId: request.brand?.id ?? '',
            })}
            variant={'sky'}
            className={'tw-underline-offset-4 hover:!tw-underline'}
          >
            {request.brand?.name ??
              t('common:unset', {
                defaultValue: '-',
              })}
          </Link>
        </If>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:type_2', {
            defaultValue: 'Video request type',
          })}
        </Label>
        <p>
          {t(`models/request:type.${request.type}`, {
            defaultValue: t('common:unknown', {
              defaultValue: 'Unknown',
            }),
          })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:resolutions.main_size', {
            defaultValue: 'Main size',
          })}
        </Label>
        <p>
          {request.resolutions?.main_size ??
            t('common:unknown', {
              defaultValue: 'Unknown',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:resolutions.resize', {
            defaultValue: 'Resizes',
          })}
        </Label>
        <p>
          {request.resolutions?.resize?.join(', ') ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('attributes/request:description.total_length', {
            defaultValue: 'Total length',
          })}
        </Label>
        <p className={'tw-break-all'}>
          {request.totalLength ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestInformationCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<FileText />}
      title={
        t('common:information', {
          defaultValue: 'Information',
        })!
      }
      open={open}
      onOpenChange={(open) => setOpen(open)}
      variant={'shadow'}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestExtrasCardContent: React.FC<React.ComponentProps<typeof CollapsibleCardContent>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('common:music', {
            defaultValue: 'Music',
          })}
        </Label>
        <p>
          {request.supporting_materials?.music_preference ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-12 tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-mb-0'}>
          {t('common:captions', {
            defaultValue: 'Captions',
          })}
        </Label>
        <p>
          {request.extra?.subtitles ??
            t('common:unset', {
              defaultValue: '-',
            })}
        </p>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestExtrasCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<Dessert />}
      title={
        t('common:extras', {
          defaultValue: 'Extras',
        })!
      }
      open={open}
      onOpenChange={(open) => setOpen(open)}
      variant={'shadow'}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestAssetCommentWrapper = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(({ className, children, ...props }, ref) => {
  return (
    <span
      {...props}
      ref={ref}
      className={cn('tw-break-all tw-rounded-lg tw-bg-neutral-50 tw-p-3', className)}
    >
      {children}
    </span>
  );
});
RequestAssetCommentWrapper.displayName = 'RequestAssetCommentWrapper';

const FileCardsGridContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn('tw-col-span-12 tw-grid tw-grid-cols-1 tw-gap-4', className)}
    >
      {children}
    </div>
  );
});
FileCardsGridContainer.displayName = 'FileCardsGridContainer';

const FileCardsGrid: React.FC<
  React.ComponentProps<typeof FileCardsGridContainer> & {
    files?: ReturnType<typeof useDecoratedRequestContext>['media'];
    fallback?: React.ReactNode;
  }
> = ({ children, files = [], fallback, ...props }) => {
  return (
    <FileCardsGridContainer {...props}>
      <If when={!children} else={children}>
        <If when={!!files.length} else={fallback}>
          {files?.map((media, i) => (
            <FileCard
              key={i}
              fileName={media.file_name}
              fileSize={media.size}
              mimeType={media.mime_type}
              download={media.index_url}
              thumbnail={
                media.generated_conversions.thumbnail ?? media.generated_conversions.poster
              }
            />
          ))}
        </If>
      </If>
    </FileCardsGridContainer>
  );
};

const RequestMediaSourceCardContent: React.FC<
  React.ComponentProps<typeof CollapsibleCardContent>
> = ({ className, children, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  const { toast } = useToast();

  const comments: {
    target: string;
    value: string;
  }[] = [];

  request.media.forEach((m) => {
    if (!m.comment) {
      return;
    }

    comments.push({
      target: m.file_name,
      value: m.comment,
    });
  });

  request.assets?.forEach((a) => {
    if (!a.comment) {
      return;
    }

    comments.push({
      target: a.url,
      value: a.comment,
    });
  });

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-8', className)} {...props}>
      <RequestInformationCardContentRow className={'tw-gap-4 tw-font-normal'}>
        <Label size={'lg'} className={'tw-col-span-12 tw-mb-0'}>
          {t('common:files', {
            defaultValue: 'Files',
          })}
        </Label>
        <If when={!children} else={children}>
          <If when={request.hasMedia} else={t('common:unset', { defaultValue: '-' })}>
            <FileCardsGrid
              className={
                'sm:tw-grid-cols-2 lg:tw-grid-cols-3 laptop:tw-grid-cols-4 desktop:tw-grid-cols-6'
              }
              files={request.media}
            />
          </If>
        </If>
      </RequestInformationCardContentRow>

      <RequestInformationCardContentRow className={'tw-gap-4'}>
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:links', {
            defaultValue: 'Links',
          })}
        </Label>
        <If
          when={!!request.assets?.length}
          else={t('common:unset', {
            defaultValue: '-',
          })}
        >
          {request.assets?.map(({ url }, i) => (
            <div key={i} className={'tw-col-span-2 tw-flex tw-gap-2 md:tw-gap-4'}>
              <div
                className={
                  'tw-flex tw-grow tw-items-center tw-gap-3 tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-transition-colors'
                }
              >
                <div>
                  <Link2Icon className={'tw-size-5'} />
                </div>
                <div className={'tw-relative tw-flex tw-w-full tw-grow tw-items-center'}>
                  <span className={'tw-absolute tw-inset-0 tw-flex tw-items-center'}>
                    <Href
                      className={'tw-truncate'}
                      underline={'hover'}
                      variant={'sky'}
                      href={url}
                      rel={'noreferrer'}
                      target={'_blank'}
                    >
                      {url}
                    </Href>
                  </span>
                </div>
              </div>

              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      type={'button'}
                      className={'tw-h-12 tw-w-12 tw-p-3'}
                      variant={'ghost'}
                      onClick={() => {
                        navigator.clipboard.writeText(url).then(() => {
                          toast({
                            className: 'tw-text-base',
                            description: t('Link copied to clipboard'),
                          });
                        });
                      }}
                    >
                      <Copy size={20} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {t('actions:copy_link', { defaultValue: 'Copy link' })}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ))}
        </If>
      </RequestInformationCardContentRow>

      <RequestInformationCardContentRow className={'tw-gap-4'}>
        <Label size={'lg'} className={'tw-col-span-12 tw-mb-0'}>
          {t('common:file_comments', {
            defaultValue: 'File comments',
          })}
        </Label>
        <If
          when={!!comments.length}
          else={t('common:unset', {
            defaultValue: '-',
          })}
        >
          <div className={'tw-col-span-12 tw-flex tw-flex-col tw-items-stretch tw-gap-4'}>
            {comments.map((c, i) => (
              <RequestAssetCommentWrapper key={i}>
                <b className={'tw-font-bold tw-text-text'}>{c.target}</b>{' '}
                <p className={'tw-inline tw-font-normal'}>{c.value}</p>
              </RequestAssetCommentWrapper>
            ))}
          </div>
        </If>
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestMediaSourceCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<FileVideo2 />}
      title={
        t('common:media_source', {
          defaultValue: 'Media source',
        })!
      }
      open={open}
      variant={'shadow'}
      onOpenChange={(open) => setOpen(open)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestDetailsCard: React.FC<React.ComponentProps<typeof CollapsibleCard>> = ({
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle(true);

  return (
    <CollapsibleCard
      icon={<PenLine />}
      title={
        t('common:details', {
          defaultValue: 'Details',
        })!
      }
      open={open}
      variant={'shadow'}
      onOpenChange={(open) => setOpen(open)}
      {...props}
    >
      {children}
    </CollapsibleCard>
  );
};

const RequestDetailsCardContent: React.FC<React.ComponentProps<typeof CollapsibleCardContent>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();

  return (
    <CollapsibleCardContent className={cn('tw-grid tw-grid-rows-1 tw-gap-2', className)} {...props}>
      <RequestInformationCardContentRow
        className={'tw-gap-6 tw-break-all tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:description', {
            defaultValue: 'Description',
          })}
        </Label>
        <p
          className={'tw-col-span-2 tw-whitespace-pre-line'}
          dangerouslySetInnerHTML={{
            __html: Str.wrapHrefs(
              dompurify.sanitize(
                request.description?.video_request_description ??
                  t('common:unset', {
                    defaultValue: '-',
                  })!,
              ),
            ),
          }}
        />
      </RequestInformationCardContentRow>
      <RequestInformationCardContentRow
        className={'tw-gap-6 tw-break-all tw-border-b tw-border-neutral-100 tw-py-4'}
      >
        <Label size={'lg'} className={'tw-col-span-2 tw-mb-0'}>
          {t('common:examples', {
            defaultValue: 'Examples',
          })}
        </Label>
        <p
          className={'tw-col-span-2 tw-whitespace-pre-line'}
          dangerouslySetInnerHTML={{
            __html: Str.wrapHrefs(
              dompurify.sanitize(
                request.extra?.examples ??
                  t('common:unset', {
                    defaultValue: '-',
                  })!,
              ),
            ),
          }}
        />
      </RequestInformationCardContentRow>
    </CollapsibleCardContent>
  );
};

const RequestAttentionRequestedAlert: React.FC = () => {
  const { t } = useTranslation();
  const { attention_text, requested_attention_at } = useDecoratedRequestContext();

  return (
    <If when={!!requested_attention_at}>
      <AlertDismissible
        layout={'flex'}
        variant={'warning'}
        title={'Attention'}
        message={
          attention_text ??
          t('common:unset', {
            defaultValue: '-',
          })
        }
      />
    </If>
  );
};

const RequestSummaryContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  Omit<React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>, 'value'>
>(({ className, ...props }, ref) => {
  return (
    <TabsContent
      ref={ref}
      className={cn('tw-relative tw-grow', className)}
      value={'summary'}
      {...props}
    >
      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <RequestAttentionRequestedAlert />

        <RequestInformationCard>
          <RequestInformationCardContent />
        </RequestInformationCard>

        <RequestExtrasCard>
          <RequestExtrasCardContent />
        </RequestExtrasCard>

        <RequestMediaSourceCard>
          <RequestMediaSourceCardContent />
        </RequestMediaSourceCard>

        <RequestDetailsCard>
          <RequestDetailsCardContent />
        </RequestDetailsCard>
      </div>
    </TabsContent>
  );
});

const RequestDefaultPageMainContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} {...props}>
      <Tabs defaultValue="summary" className={'tw-flex tw-h-full tw-flex-col tw-gap-2'}>
        <TabsList
          className={
            'tw-hidden tw-w-full tw-gap-2 tw-overflow-x-auto tw-overflow-y-clip tw-bg-background tw-p-2'
          }
        >
          <TabsTrigger className={'tw-grow'} value="summary">
            {t('common:summary', {
              defaultValue: 'Summary',
            })}
          </TabsTrigger>
        </TabsList>

        <RequestSummaryContent />
      </Tabs>
    </div>
  );
});
RequestDefaultPageMainContent.displayName = 'RequestDefaultPageMainContent';

export {
  RequestDefaultPageMainContent,
  RequestInformationCard,
  RequestExtrasCard,
  RequestMediaSourceCard,
  RequestDetailsCard,
  RequestPageContainer,
  RequestPageContentMain,
  RequestDefaultPageSkeleton,
  RequestInformationCardContent,
  RequestExtrasCardContent,
  RequestMediaSourceCardContent,
  RequestDetailsCardContent,
  RequestAttentionRequestedAlert,
  FileCardsGridContainer,
  FileCardsGrid,
};
