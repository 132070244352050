import { Loader2 } from 'lucide-react';
import React from 'react';
import { Dialog, DialogOverlay } from 'src/components/ui/dialog';

interface Props {
  loading: boolean;
}

const ModalLoader: React.FC<Props> = ({ loading }): JSX.Element => {
  return (
    <Dialog modal open={loading}>
      <DialogOverlay className={'tw-flex tw-items-center tw-justify-center'}>
        <Loader2 size={30} className={'tw-animate-spin tw-text-white'} />
      </DialogOverlay>
    </Dialog>
  );
};

export default ModalLoader;
