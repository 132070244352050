import { ValueOfObject } from 'src/types/generics';
import { HasStatus, SortableRequest } from 'src/api/Interfaces';
import { HookFormMediaObject } from 'src/components/Form/S3UploadInput';

const orderType = {
  ad: 'ad',
  talkingHead: 'talking_head',
  youtubeVideo: 'yt_video',
  podcast: 'podcast',
  vlog: 'vlog',
  reel: 'reel',
  tiktok: 'tiktok',
  youtubeShorts: 'yt_shorts',
  story: 'story',
  courseVideo: 'course_video',
  tutorial: 'tutorial',
  motionGraphics: 'motion_graphics',
  other: 'other',
} as const;

type OrderType = ValueOfObject<typeof orderType> | string;

const requestMultipleOutcomesType = {
  custom: 'custom',
  editorChoice: 'editor_choice',
} as const;

type RequestMultipleOutcomesType = ValueOfObject<typeof requestMultipleOutcomesType>;

const requestPriority = {
  low: 1,
  medium: 2,
  high: 3,
  critical: 4,
} as const;

type RequestPriorityType = ValueOfObject<typeof requestPriority>;

const requestPriorityLabels = {
  [requestPriority.low]: 'Low',
  [requestPriority.medium]: 'Medium',
  [requestPriority.high]: 'High',
  [requestPriority.critical]: 'Critical',
};

const requestStatus = {
  draft: 'draft',
  queued: 'queued',
  currentlyEditing: 'currently_editing',
  delivered: 'delivered',
  complete: 'complete',
} as const;

type RequestStatus = ValueOfObject<typeof requestStatus>;

const requestListType = {
  list: 'list',
  kanban: 'kanban',
} as const;

type RequestListType = ValueOfObject<typeof requestListType>;

export const requestAspectRatio = {
  widescreen: '16x9',
  verticalWidescreen: '9x16',
  standardMonitor: '4x3',
  classicFilm: '3x2',
  cinemaScope: '21x9',
  portrait: '4x5',
  square: '1x1',
  videoQuote: '1x1 Video Quote',
};

export type RequestAspectRatio = ValueOfObject<typeof requestAspectRatio>;

export const aspectRatioOptions = [
  {
    label: requestAspectRatio.widescreen,
    value: requestAspectRatio.widescreen,
    description: '(Youtube / Web)',
  },
  {
    label: requestAspectRatio.portrait,
    value: requestAspectRatio.portrait,
    description: '(Facebook & Instagram Feed)',
  },
  {
    label: requestAspectRatio.verticalWidescreen,
    value: requestAspectRatio.verticalWidescreen,
    description: '(TikTok, Reels, Stories, Shorts)',
  },
  {
    label: requestAspectRatio.square,
    value: requestAspectRatio.square,
    description: '(Ads, LinkedIn)',
  },
  {
    label: `${requestAspectRatio.videoQuote}`,
    value: `${requestAspectRatio.videoQuote}`,
  },
];

interface RequestResolutionsObject {
  main_size?: string;
  resize?: string[];
}

interface RequestAssetUrl {
  id: string;
  url: string;
  comment?: string;
}

interface RequestExtraObject {
  subtitles?: string;
  end_card?: boolean;
  examples?: string;
}

type TotalLengthRequest = {
  text?: string | number | null;
  value?: number;
  unit?: string;
};

type TotalLengthResponse = {
  value?: number;
  unit?: string;
};

interface MultipleOutcomesResponse {
  outcome_type: RequestMultipleOutcomesType;
  outcomes: OutcomeResponse[];
}

interface OutcomeResponse extends HasStatus<RequestStatus> {
  title: string;
  type?: OrderType;
  description: string;
  is_default?: boolean;
  total_length?: TotalLengthResponse;
  created_at: string;
  video_template?: {
    id: string;
    title: string;
    aspect_ratio: string;
  }
}

interface SortRequest extends SortableRequest {
  request_id: string;
}

interface MultipleOutcomesRequest {
  outcome_type: RequestMultipleOutcomesType;
  outcomes: OutcomeRequest[];
}

interface OutcomeRequest {
  title: string;
  type?: OrderType;
  description: string;
  is_default?: boolean;
  total_length?: TotalLengthRequest;
  video_template_id?: string;
  aspect_ratio?: string;
}

type RequestCounts = {
  total: string[];
  new: string[];
};

type RequestsCountsResponse = {
  [key in RequestStatus]: RequestCounts;
};

interface RequestFormObject {
  status: RequestStatus;
  type?: string;
  title: string;
  video_template_id?: string;
  priority: RequestPriorityType;
  media: {
    footage: HookFormMediaObject[];
  };
  supporting_materials: {
    music_preference?: string;
  };
  assets: RequestAssetUrl[];
  extra: RequestExtraObject;
  description?: {
    video_request_description?: string;
  };
  total_length: TotalLengthRequest;
  with_multiple_outcomes: boolean;
  multiple_outcomes: MultipleOutcomesRequest;
  brand_id?: string;
  resolutions?: RequestResolutionsObject;
  is_draft: boolean;
}

export type {
  RequestStatus,
  RequestListType,
  OrderType,
  TotalLengthRequest,
  TotalLengthResponse,
  RequestResolutionsObject,
  RequestAssetUrl,
  SortRequest,
  RequestMultipleOutcomesType,
  RequestPriorityType,
  MultipleOutcomesResponse,
  OutcomeResponse,
  MultipleOutcomesRequest,
  OutcomeRequest,
  RequestsCountsResponse,
  RequestCounts,
  RequestExtraObject,
  RequestFormObject,
};
export {
  orderType,
  requestMultipleOutcomesType,
  requestPriority,
  requestPriorityLabels,
  requestStatus,
  requestListType,
};
